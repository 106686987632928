import * as React from "react"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import CallToAction from "../components/CallToAction"
import Heading from "../components/Heading"
import "../styles/our-work.css"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faExternalLinkAlt } from "@fortawesome/free-solid-svg-icons"
import FancyLink from "../components/FancyLink"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import "../styles/slick-custom.css"

import Slider from "react-slick"

import Layout from "../components/Layout"
import Seo from "../components/Seo"

import { graphql } from "gatsby"

export const OurWorkPage = ({ data }) => {
  const projects = data.projects.edges
  const testimonials = data.testimonials.edges

  console.log(testimonials)
  const reviewSliderSettings = {
    dots: true,
    infinite: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
  }

  return (
    <Layout>
      <Seo
        title="Our Work"
        description="See how we have partnered with our clients to unlock the potential of their businesses and brands and ultimately grow with better outcomes with a sample of our work."
      />
      <Heading>Our Work</Heading>
      <section className="split halves inset">
        {projects.map(project => {
          return (
            <div>
              <h3 className="text-center">{project.node.frontmatter.title}</h3>
              <a href={project.node.frontmatter.link} target="_blank">
                <div className="overlay-container">
                  <GatsbyImage
                    className="overlay-image"
                    alt={project.node.frontmatter.title}
                    image={getImage(project.node.frontmatter.image)}
                  ></GatsbyImage>
                  <div className="overlay">
                    <div class="overlay-text">
                      <FontAwesomeIcon icon={faExternalLinkAlt} />
                    </div>
                  </div>
                </div>
              </a>
              <div dangerouslySetInnerHTML={{ __html: project.node.html }} />
              <p>
                <FontAwesomeIcon icon={faExternalLinkAlt} />{" "}
                <FancyLink
                  externalLink={project.node.frontmatter.link}
                  target="_blank"
                >
                  {project.node.frontmatter.link}
                </FancyLink>
              </p>
            </div>
          )
        })}
      </section>
      <section className="shaded">
        <div className="inset">
          <Heading>People love us!</Heading>
          <Slider {...reviewSliderSettings}>
            {testimonials.map(testimonial => {
              return (
                <div className="testimonial">
                  <GatsbyImage
                    style={{ maxWidth: "200px" }}
                    alt={testimonial.node.frontmatter.name}
                    image={getImage(testimonial.node.frontmatter.image)}
                  />
                  <h3>{testimonial.node.frontmatter.name}</h3>
                  <h4>{testimonial.node.frontmatter.jobTitle}</h4>
                  <p
                    className="testimonial-text"
                    dangerouslySetInnerHTML={{ __html: testimonial.node.html }}
                  ></p>
                </div>
              )
            })}
          </Slider>

          <div className="google-links">
            <FancyLink
              externalLink={`https://www.google.com/search?q=rocky+mountain+web&rlz=1C1GCEB_enCA939CA939&sxsrf=AOaemvKbcmxe4CJwr55E25L8XOVWk-OAVw%3A1630693175553&ei=N2cyYe2VIbK90PEP99iBiAQ&oq=rocky+mountain+web&gs_lcp=Cgdnd3Mtd2l6EAMyBQgAEIAEMgUIABCABDIFCAAQgAQyBQgAEIAEMgYIABAWEB4yBggAEBYQHjIGCAAQFhAeMgYIABAWEB4yBggAEBYQHjIGCAAQFhAeOgUIABCRAjoRCC4QgAQQsQMQgwEQxwEQowI6CwgAEIAEELEDEIMBOg4ILhCABBCxAxDHARCjAjoLCC4QgAQQsQMQgwE6CAgAELEDEIMBOgQIABBDOgsILhDHARCvARCRAjoICC4QsQMQkQI6BAguEEM6CAgAEIAEELEDOg4ILhCABBCxAxDHARDRAzoRCC4QgAQQsQMQgwEQxwEQrwE6CwguEIAEEMcBEK8BOggILhCABBCxAzoRCC4QsQMQgwEQxwEQrwEQkQI6BwguELEDEEM6DgguEIAEEMcBEK8BEJMCOgQIABAKOhAILhCxAxCDARDHARCvARAKOgcIABCxAxAKOgoILhDHARCvARAKOgUILhCABDoICAAQgAQQyQNKBAhBGABQ9SdYg0dg5EhoAXABeACAAaMBiAH-E5IBBDAuMTmYAQCgAQHAAQE&sclient=gws-wiz&ved=0ahUKEwitjd27tePyAhWyHjQIHXdsAEEQ4dUDCA4&uact=5#lrd=0x5370cb3dd7f8cb6d:0xc18e3b17ec4fc66c,1,,,`}
              target="_blank"
              className="fancy-link"
            >
              See our Google Reviews
            </FancyLink>
          </div>
        </div>
      </section>

      <CallToAction
        heading="Contact Us"
        body="You've got to admit that we make nice websites. So why not get in touch today?"
        buttonText="Contact Us"
        bgColor="rgb(36, 110, 185)"
        link="contact"
      />
    </Layout>
  )
}

export const query = graphql`
  query OurWorkPage {
    projects: allMarkdownRemark(
      sort: { fields: frontmatter___sort, order: ASC }
      filter: { fields: { collection: { eq: "projects" } } }
    ) {
      edges {
        node {
          frontmatter {
            title
            date
            description
            link
            image {
              childImageSharp {
                gatsbyImageData(width: 900, placeholder: BLURRED)
              }
            }
          }
          html
        }
      }
    }
    testimonials: allMarkdownRemark(
      filter: { fields: { collection: { eq: "testimonials" } } }
    ) {
      edges {
        node {
          frontmatter {
            name
            jobTitle
            image {
              childImageSharp {
                gatsbyImageData(width: 400, placeholder: BLURRED)
              }
            }
          }
          html
        }
      }
    }
  }
`

export default OurWorkPage
